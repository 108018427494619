const {
  REACT_APP_BASE_URL = 'https://quickcam.promanager.online',
  REACT_APP_KEYCLOAK_ACCOUNT = 'https://account.quickcam.promanager.online/realms/main',
  REACT_APP_KEYCLOAK_CLIENT_ID = 'quickcam',
  REACT_APP_KEYCLOAK_REDIRECT_URI = '',
  REACT_APP_APP_REF_URL = '',
  REACT_APP_API_BASE_URL = 'https://api.promanager.online',
  REACT_APP_APPLICATION_DOMAIN = 'https://quickcam.app',
  REACT_APP_ONBOARDING_API_BASE_URL = '',
  REACT_APP_POSTHOG_API_KEY = '',
  REACT_APP_POSTHOG_API_HOST = '',
  REACT_APP_FACEBOOK_APP_ID = '223997756367248',
  REACT_APP_GOOGLE_CLIENT_ID = '867114240537-9h5moptgthqk158goqso1ufhi2noq0rl.apps.googleusercontent.com',
  REACT_APP_OPENCAGE_DATA_MAP_KEY = '',
  REACT_APP_HELPDESK_API_URL = '',
} = process.env;

const ENVIRONMENTS = {
  BASE_URL: REACT_APP_BASE_URL,
  KEYCLOAK_ACCOUNT: REACT_APP_KEYCLOAK_ACCOUNT,
  KEYCLOAK_CLIENT_ID: REACT_APP_KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REDIRECT_URI: REACT_APP_KEYCLOAK_REDIRECT_URI,
  APPLICATION_DOMAIN: REACT_APP_APPLICATION_DOMAIN,
  ONBOARDING_API_BASE_URL: REACT_APP_ONBOARDING_API_BASE_URL,
  POSTHOG_API_HOST: REACT_APP_POSTHOG_API_HOST,
  POSTHOG_API_KEY: REACT_APP_POSTHOG_API_KEY,
  API_BASE_URL_V2: REACT_APP_API_BASE_URL,
  OPENCAGE_DATA_MAP_KEY: 'b351f02913f34f95bf8b86d091ff586a',
  FACEBOOK_APP_ID: REACT_APP_FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID,
  HELPDESK_API_URL: 'https://api-alpha-admin-panel.promanager.online',
  APP_REF_URL: REACT_APP_APP_REF_URL || 'https://ref.alpha.quickcam.com',
};

console.log('ENVIRONMENTS', ENVIRONMENTS);

export default ENVIRONMENTS;
